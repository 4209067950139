@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

#nprogress .bar {
  background: limegreen !important;
}

.green-gradient-bg {
  background: linear-gradient(45deg, #416a67, #019ba3);
}

.ant-float-btn {
 box-shadow: none;
}

.ant-btn:hover{
  color: #ffffff !important;
}

.custom-button:hover{
  color: #3788FD !important;
}
.categoryHome {
  border: solid white 1px;
  flex-grow: 1;
  border-radius: 8px;
  flex-basis: 0;
  text-align: center;
  margin: 16px;
  min-height: 200px;
  position: relative;
  max-width: 500px;
}

.categoryTitleHome {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 17.02px;
  font-weight: bold;
}

.categoryBoxShadow {
  box-shadow: "0px 2px 4px rgba(0, 0, 0, 0.4)",
}

.categoryDescriptionHome {
  display: none;
  background-color: #245958;
  height: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
}

/* .categoryHome:hover .categoryTitleHome {
  display: none;
}

.categoryHome:hover .categoryDescriptionHome {
  display: block;
} */

.css-7nbrit-control{
   border-color: #e5e7eb !important;
}

.css-1nlpctl-control{
    border-color: #e5e7eb !important;
}

.css-1nlpctl-control:hover{
    border-color: #e5e7eb !important;
}

.css-115eup5-control{
    border-color: #0b0f15 !important;
}

.checklistRow {
    display: table;
    width: 100%; /*Optional*/
    table-layout: fixed; /*Optional*/
    border-spacing: 10px; /*Optional*/
}

.checklistColumn {
    display: table-cell;
    background-color: red; /*Optional*/
}
.ant-select-dropdown {
  position: fixed !important;
  top: 100%; 
  left: 0;
  width: 100%;
  margin-top: 5px; 
  z-index: 1000;
}
.roundedRectangleButton {
  background-color: #537372;
  border: none;
  color: white;
  padding: 3px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
  border-radius: 4px;
  font-weight: bold;
  position: absolute;
  right: 0;
}

.roundedRectangleBorderButton {
  background-color: white;
  border: 2px solid #537372;
  color: #537372;
  padding: 3px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 10px;
  border-radius: 4px;
  font-weight: bold;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.file-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid #537372;
  border-radius: 5px;
  width: 150px;
}

.delete-button {
  margin-left: auto;
  cursor: pointer;
}

.ant-result-info .ant-result-icon > .anticon {
  color: red !important;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #3788FD !important;
}

.custom-scrollbar {
  max-height: 70vh;
  overflow-y: auto;

}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    margin-top: 40px;
    margin-bottom: 40px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #3788fd;
  border-radius: 10px;
}

.moveLeft {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

}

.moveLeftSubcategory {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
}

.ant-tooltip .ant-tooltip-inner {
  min-height: 32px;
  width: max-content;
  padding: 6px 6px;
  white-space: nowrap;
  color: white;
  text-align: start;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-tooltip-arrow {
  visibility: hidden;
}

.custom-success-notification .ant-notification-notice-icon-success {
  display: none;
}

.custom-success-notification .ant-notification-notice-close {
  margin-top:2px;
  margin-left:10px;
}
.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right:10px;
  font-size: 16px;
  color:white
}

.ant-collapse-content-box, .ant-collapse-header, .ant-collapse-item-active {
  background-color: white;
}

.custom-select {
  width: 100%;
  margin-top: 2.5px;
  padding: 3.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}
.custom-select-dsr {
  width: 100%;
  margin-top: 2.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-select-datepicker{
  margin-top: 2.5px;
  padding: 3.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-select-textarea{
  margin-top: 2.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-padding-column{
  padding: 0px !important;
}
.ant-select-item-option-selected {
  background-color: #5E5E5E !important;
}

.custom-select-selector{
  width: 100%;
  margin-top: 2.5px;
  border: 2px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.custom-select:hover,
.custom-select:focus,
.custom-select.ant-select-focused,
.custom-select.ant-select-open {
  border: 2px solid #0099ff;
}

.custom-select-grey:hover {
  border: 2px solid #DDD
}

.ant-table-tbody > tr > td {
  white-space: pre-wrap !important;
  word-break: break-word !important;
}

#overviewTable .ant-table-selection-column{
  padding-left: 26px;
  padding-right: 26px;
}

#ListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
}

#ListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
}
#ListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#groupUserUploadTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
}

#groupUserUploadTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
}
#groupUserUploadTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

.add-modal-container .ant-modal-close {
  display: none; /* Hide the default close icon */
}

.overflow-x-auto {
  background-color: #ffffff;
}

.custom-panel .ant-collapse-header {
  background-color: white;
  transition: background-color 0.3s ease;
}

.outer-panel.active > .ant-collapse-header {
  background-color: #E7F1FF;
  color: #0C63E4;
}

.inner-panel.active > .ant-collapse-header {
  background-color: #E7F1FF;
  color: #0C63E4;
}

#defaultButton {
  background-color: transparent;
  border-color: #59A9FF;
  color: #59A9FF;
}

#selectedButton {
  background-color: #59A9FF;
  border-color: #59A9FF;
  color: white;
}

.custom-placeholder .ant-select-selection-placeholder {
  color: white !important;
}
.custom-placeholder .ant-select-arrow {
  color: white;
}
.custom-placeholder .ant-select-selection-item {
  color: white !important;
}

.dragger-parent{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
}

.ant-upload.ant-upload-drag{
  width: 40%;
  height: 60%;
  margin: 20px;
  background-color: rgb(251, 250, 250);
  float: right;
}

.ant-upload-list.ant-upload-list-picture{
  width: 50%;
  margin: 20px;
}

.ant-form-item-explain,
.ant-form-item-explain-error,
.ant-form-item-explain .ant-form-item-explain-error,
.ant-form-item-explain .ant-form-item-explain-error-zoom-enter,
.ant-form-item-explain .ant-form-item-explain-error-zoom-enter-active,
.ant-form-item-explain .ant-form-item-explain-error-zoom-leave,
.ant-form-item-explain .ant-form-item-explain-error-zoom-leave-active {
  transition: none !important;
}

.react-flow__node {
  z-index: -1 !important;
}

.radio-break-line {
  padding-inline: 10px;
  white-space: break-word; /* Allows text to wrap */
  word-wrap: break-word; /* Ensures words break when needed */
  overflow-wrap: break-word; /* Handles overflow wrapping */
  height: auto; /* Allows height to adjust based on content */
  line-height: 1.7;
}
.custom-checkbox .ant-checkbox {
  display: none;
}

.custom-checkbox .ant-checkbox-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  display: inline-block;
  position: relative;
}

.custom-checkbox .ant-checkbox-inner {
  display: none; /* Hide the default inner checkbox */
}

.custom-checkbox .ant-checkbox-checked + .ant-checkbox-label {
  background-color: #1890ff; /* Checked background color */
  color: #fff; /* Checked text color */
  border: 1px solid #1890ff; /* Checked border color */
}

.custom-checkbox .ant-checkbox-label {
  display: inline-block;
  border: 1px solid #d9d9d9; /* Default border color */
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  background-color: #fff;
  color: #1890ff;
}

.custom-checkbox .ant-checkbox-label:hover {
  background-color: #e6f7ff;
  border-color: #1890ff;
}

.sider-bar {
  z-index: 1000 !important;
  top: 0 !important;
  left: 0 !important;
  position: fixed !important;
  height: 100vh !important;
  overflow-y: auto !important;
}

#consentRecordListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#consentRecordListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#consentRecordListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#dataMappingListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#dataMappingListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important
}
#dataMappingListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#dataMappingUploadTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align: left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;

} 
#dataMappingUploadTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
#dataMappingUploadTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#dsrListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#dsrListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#dsrListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#incidentListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  overflow: hidden;
} 
#incidentListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#incidentListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

/* PIA List Table */
.fixed-width-button {
  width: 150px;
  text-align: center;
}
#piaListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#piaListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  }
#piaListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

/* Policy Records List Table */
#privacyPolicyListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#privacyPolicyListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#privacyPolicyListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

#ropaListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important; 
  
} 
#ropaListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  white-space: pre-wrap !important;
  word-break: break-word !important;
}
#ropaListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

.fixed-width-button {
  width: 150px;
  text-align: center;
}
#triaListTable .ant-table-thead > tr > th {
  background-color: #d1d5db !important;
  color: black !important;
  text-align:left !important;
  border-top: 1px solid black !important;
  border-radius: 0 !important; 
  font-size: 16px !important;
  font-weight: 600 !important;
} 
#triaListTable .ant-table-tbody > tr > td {
  border-top: 1px solid black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
#triaListTable .ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid black !important;
}

.custom-padding-button {
  padding: 26px !important
}

#checkableButton .ant-tag-checkable:hover {
  background-color: white !important;
  color: none !important;
  border-color: none !important;
}

.custom-notVendor {
  background-color: #f5f4f4 !important;
  color: gray !important;
  border-color: #e5e7eb !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.custom-sameOption {
  background-color: #3788FD !important;
  color: white !important;
  border-color: #3b82f6 !important;
  border-width: 1px !important;
  border-style: solid !important;
}
.custom-notSameOption {
  background-color: white !important;
  color: #3788FD !important;
  border-color: #3788FD !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.custom-notResponden {
  background-color: #f5f4f4 !important;
  color: gray !important;
  border-color: #e5e7eb !important;
  border-width: 1px !important;
  border-style: solid !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.custom-responden-sameOption {
  background-color: #3788FD !important;
  color: white !important;
  border-color: #3b82f6 !important;
  border-width: 1px !important;
  border-style: solid !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  z-index: 0 !important;
}
.custom-responden-notSameOption {
  color: #3788FD !important;
  border-color: #3788FD !important;
  border-width: 1px !important;
  border-style: solid !important;
  padding-left: 21px !important;
  padding-right: 21px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.readOnlyQuill .ql-container {
  cursor: not-allowed;
  }

.dont-hover-bg {
  background-color: white !important;/* Replace with your desired default background color */
}
.dont-hover-bg:hover {
  background-color: none !important; /* Replace with your desired hover background color */
}

.dont-hover-bg-notResponden {
  background-color: none !important;/* Replace with your desired default background color */
}

.dont-hover-bg-notResponden:hover {
  background-color: inherit !important; /* Replace with your desired hover background color */
  color : #818180 !important
}
  